import Css from "./style.module.scss";

import * as CompanyEmailValidator from "company-email-validator";
import React, { useCallback } from "react";

import { HTTPS_STATUS_TOO_MANY_REQUESTS } from "utils/request";
import { IconBuilding, IconMail, IconPencil, IconUser, IconValid } from "lib/icons";
import { sendContactFormData } from "utils/api";
import { showDialog } from "lib/common/Dialogs";
import Form, { FormField } from "lib/common/Form";
import validate from "utils/validate";

const MESSAGE_MAX_LENGTH = 5000;

const INITIAL_VALUES = {
  userName: "",
  userEmail: "",
  message: "",
};

const DialogContent = () => {
  return (
    <div className={Css.dialogContent}>
      <IconValid />
      <div className={Css.textLarge}>Your message has been successfully submitted</div>
      <div>We will contact you by E-mail</div>
    </div>
  );
};

const validator = (model) => {
  const errors = validate(model, {
    userName: { required: true },
    userEmail: { required: true, email: true },
    message: { required: true },
  });

  if (!CompanyEmailValidator.isCompanyEmail((model.userEmail || "").toLowerCase())) {
    showDialog({
      message: `Please enter your company's email address.
  Emails from free services such as gmail, yahoo, and others aren’t acceptable`,
    });

    errors.userEmail = true;
  }

  return errors;
};

const ContactFormCzechRepublic = ({ withCompanyName }) => {
  const handleSubmit = useCallback(async(values) => {
    try {
      const response = await sendContactFormData({
        ...values,
        message: withCompanyName ? `[Název společnosti: ${values.message}]` : values.message,
      });

      if (!response.sent) throw response;
      if (window.gtag) window.gtag("event", "contact_form_submitted");
      showDialog({ message: <DialogContent /> });
    } catch (exception) {
      // eslint-disable-next-line no-console
      console.log("exception", exception);
      if (exception.status === HTTPS_STATUS_TOO_MANY_REQUESTS) {
        showDialog({ message: "Příliš mnoho požadavků. Zkuste to později." });
      } else {
        showDialog({ message: "Něco se pokazilo. Zkuste to později." });
      }

      return {};
    }

    return null;
  }, [withCompanyName]);

  return (
    <Form
      className={Css.form}
      initialValues={INITIAL_VALUES}
      validator={validator}
      onSubmit={handleSubmit}>
      <FormField
        name="userName"
        placeholder="Jméno"
        icon={<IconUser />} />
      <FormField
        type="email"
        name="userEmail"
        placeholder="example@mycompany.com"
        icon={<IconMail />} />
      {withCompanyName
        ? (
          <FormField
            name="message"
            placeholder="Společnost"
            icon={<IconBuilding />}
            maxLength={MESSAGE_MAX_LENGTH} />
        )
        : (
          <FormField
            name="message"
            placeholder="Vaše zpráva"
            maxLength={MESSAGE_MAX_LENGTH}
            icon={<IconPencil />}
            multiline />
        )}
      <button type="submit">
        Odeslat
      </button>
    </Form>
  );
};

export default ContactFormCzechRepublic;
