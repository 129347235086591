import Css from "./style.module.scss";

import InfoBlock from "../InfoBlock";
import React from "react";

const FeaturesCzechRepublic = () => {
  return (
    <div id="features" className={Css.features}>
      <InfoBlock
        src="/images/automated-invoice-processing.svg"
        title="Vytážení dat z dokumentů v reálném čase"
        description="Využijte sílu OCR AI a GPT-4 v reálném čase k extrakci dat z faktur a účtenek, čímž urychlíte zpracování transakcí a ušetříte cenný čas." />

      <InfoBlock
        reverse
        src="/images/fig-5.svg"
        title="Minimalizujte stále se opakující komunikaci díky našemu klientskému portálu"
        description={(
          <div className={Css.text}>
            <div>Zefektivněte spolupráci s klienty a získejte rychlejší odpovědi díky našemu uživatelsky přívětivému portálu, dostupnému na webu i jako mobilní aplikace.</div>
            <div className={Css.storeIcons}>
              <a href="https://apps.apple.com/us/app/booke-ai-client-portal/id6467752883" target="_blank">
                <img src="/images/app-store-badge.svg" alt="Download on the Apple Store" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.bookeai.clientapp" target="_blank">
                <img src="/images/google-play-badge.svg" alt="Get it on Google Play" />
              </a>
            </div>
          </div>
        )} />

      <InfoBlock
        src="/images/fig-1.svg"
        title="AI poháněné rozdělování se neustále zdokonaluje."
        description="Díky Booke trávíte méně času manuálním zpracováním dokladu a s každým použitím je systém přesnější a spolehlivější." />

      <InfoBlock
        reverse
        src="/images/fig-2.svg"
        title="Vylepšete komunikaci s klienty s menším počtem hovorů a emailů."
        description="Udržujte své klienty spokojené a váš podnik v plynulém chodu s našimi nástroji pro efektivní komunikaci a přehlednými úkoly." />
    </div>
  );
};

export default FeaturesCzechRepublic;
