import Css from "./style.module.scss";

import React from "react";

const CallToActionCzechRepublic = ({ disableAnimation = false }) => {
  return (
    <div className={Css.callToAction}>
      <div className="container">
        <div className={`${Css.container} ${disableAnimation ? "" : "fadeInZoomIn"}`}>
          <div className={Css.left}>
            <img src="/images/fig-4.svg" />
          </div>
          <div className={Css.right}>
            <div className={Css.textLarge}>Automatizujte své účetnictví s technologií AI od Booke.</div>
            <a href="/signup" className={`button ${Css.button}`}>Začněte hned</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToActionCzechRepublic;
