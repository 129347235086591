import Css from "./style.module.scss";

import React from "react";

import { CALENDLY_WIDGET_URL } from "const";

const MainCzechRepublic = () => {
  return (
    <div className={Css.wrapper}>
      <div className={Css.main}>
        <div className={`container ${Css.container}`}>
          <div className="row">
            <div className={Css.left}>
              <div className={Css.textLarge}>
                <div>
                  <span>Automatizujte své účetnictví</span>
                  <br />
                s AI poháněnou jedinou aplikací <img src="/images/lightning.png" />
                </div>
              </div>
              <div className={Css.textSmall}>
              Booke využívá umělou inteligenci k zpracování faktur a dokladů, dotazuje se klientů jedním klikem a automatizuje více vaší práce.
              </div>
              <div className={Css.buttons}>
                <a href="/signup" className={`button ${Css.button}`}>
                  <span>Začít</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none">
                    <path stroke="#fff" strokeWidth="1.5" d="m5.5 15 10-10M7.375 5H15.5v8.125" />
                  </svg>
                </a>
                <a href={CALENDLY_WIDGET_URL} className={`button stroke ${Css.button}`}>Požádat o demo</a>
              </div>
            </div>
            <div className={Css.right}>
              <img src="/images/main-block-illustration.png" alt="Booke AI" />
            </div>
          </div>
        </div>
      </div>
      <div className={Css.backedBy}>
        <div className="container">
          <div className={Css.items}>
            <div className={Css.item}>
              <span>Podporováno</span>
              <div>
                <img width="158" src="/images/alchemist-accelerator.png" />
              </div>
            </div>
            <div className={Css.item}>
              <span>Člen programu</span>
              <div>
                <img width="196" src="/images/microsoft-for-startups.png" />
              </div>
              <div>
                <img width="196" src="/images/google-for-startups.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainCzechRepublic;
