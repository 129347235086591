import React from "react";

import CallToAction from "lib/common/CallToAction";
import CallToActionCzechRepublic from "lib/common/CallToActionCzechRepublic";
import Features from "lib/common/Features";
import FeaturesCzechRepublic from "lib/common/FeaturesCzechRepublic";
import FormBlock from "lib/common/FormBlock";
import FormBlockCzechRepublic from "lib/common/FormBlockCzechRepublic";
import Infographics from "lib/common/Infographics";
import InfographicsCzechRepublic from "lib/common/InfographicsCzechRepublic";
import Layout from "lib/common/Layout";
import Main from "lib/common/Main";
import MainCzechRepublic from "lib/common/MainCzechRepublic";
import VideoFeedback from "lib/common/VideoFeedback";
import VideoFeedbackCzechRepublic from "lib/common/VideoFeedbackCzechRepublic";

import { COUNTRY_TO_LOCALE } from "const";
import { useRouter } from "next/router";

const MainBlock = () => {
  const { locale } = useRouter();

  switch (locale) {
    case COUNTRY_TO_LOCALE.CZ:
      return <MainCzechRepublic />;
    default:
      return <Main />;
  }
};

const VideoBlock = () => {
  const { locale } = useRouter();

  switch (locale) {
    case COUNTRY_TO_LOCALE.CZ:
      return <VideoFeedbackCzechRepublic />;
    default:
      return <VideoFeedback />;
  }
};

const FeaturesBlock = () => {
  const { locale } = useRouter();

  switch (locale) {
    case COUNTRY_TO_LOCALE.CZ:
      return <FeaturesCzechRepublic />;
    default:
      return <Features />;
  }
};

const mainTitle = (locale) => {
  switch (locale) {
    case COUNTRY_TO_LOCALE.CZ:
      return "Optimalizujte své účetnictví s AI poháněnou jedinou aplikací";
    default:
      return "Automate your bookkeeping with an AI-driven single app";
  }
};

const mainDescription = (locale) => {
  switch (locale) {
    case COUNTRY_TO_LOCALE.CZ:
      return "Zjednodušte účetnictví s aplikací Booke.ai, která využívá umělou inteligenci k automatizaci úkolů a zlepšení efektivity.";
    default:
      return "Streamline bookkeeping with Booke.ai, an AI-driven app that automates tasks and improves efficiency";
  }
};

const InfographicBlock = () => {
  const { locale } = useRouter();

  switch (locale) {
    case COUNTRY_TO_LOCALE.CZ:
      return <InfographicsCzechRepublic />;
    default:
      return <Infographics />;
  }
};

const ContactForm = () => {
  const { locale } = useRouter();

  switch (locale) {
    case COUNTRY_TO_LOCALE.CZ:
      return <FormBlockCzechRepublic />;
    default:
      return <FormBlock />;
  }
};

const CallToActionBlock = () => {
  const { locale } = useRouter();

  switch (locale) {
    case COUNTRY_TO_LOCALE.CZ:
      return <CallToActionCzechRepublic />;
    default:
      return <CallToAction />;
  }
};

const Home = () => {
  const { locale } = useRouter();

  return (
    <Layout index title={mainTitle(locale)} description={mainDescription(locale)}>
      <MainBlock />
      <VideoBlock />
      <FeaturesBlock />
      <InfographicBlock />
      <CallToActionBlock />
      <ContactForm />
    </Layout>
  );
};

export default Home;
