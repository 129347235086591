import Css from "./style.module.scss";

import React from "react";

import { IconBuildings, IconMapPin } from "lib/icons";

const GOOGLE_MAP_URL = "https://www.google.com/maps/place/V%C3%A1clavsk%C3%A9+n%C3%A1m.+2132%2F47,+110+00+Nov%C3%A9+M%C4%9Bsto/@50.0810084,14.4260638,17z";

const MapStatic = ({ children }) => {
  return (
    <div className={Css.mapStatic}>
      <div className={Css.tooltip}>
        <div className={Css.content}>
          <a href={GOOGLE_MAP_URL} target="_blank">
            <IconBuildings />
            <span>Booke AI s.r.o.</span>
          </a>
          <a href={GOOGLE_MAP_URL} target="_blank">
            <IconMapPin />
            <span>Václavské nám. 47, 110 00 Nové Město</span>
          </a>
        </div>
      </div>
      {children}
    </div>
  );
};

export default MapStatic;
