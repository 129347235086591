import Css from "./style.module.scss";

import React from "react";

import InViewport from "lib/common/InViewport";

const VideoFeedbackCzechRepublic = () => {
  return (
    <div className={Css.videoFeedback}>
      <InViewport>
        {({ shownOnce }) => (
          <>
            <div className={`container fadeInFromTop ${!shownOnce && "notShown"}`}>
              <div className={Css.video}>
                <iframe
                  src="https://player.vimeo.com/video/887580620?h=5eb96328f5"
                  width="640"
                  height="360"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen />
              </div>
              <h1>Proč počítat doklady? Užívejte si s námi neomezené OCR AI zdarma!</h1>
              <div className={Css.textSmall}>
                Neplaťte za každý doklad. Automatizujte své účetnictví a užívejte si neomezené zpracování faktur a dokladů s naší inovativní technologií.
              </div>
            </div>
          </>
        )}
      </InViewport>
    </div>
  );
};

export default VideoFeedbackCzechRepublic;
