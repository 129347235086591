import Css from "./style.module.scss";

import React from "react";

import ContactForm from "lib/common/ContactFormCzechRepublic";
import MapStatic from "./lib/MapStatic";

const FormBlockCzechRepublic = () => {
  return (
    <div className={Css.formBlock} id="contact_us">
      <MapStatic>
        <div className="container">
          <div className="row-m">
            <div className={Css.formContainer}>
              <div className={Css.inner}>
                <div className={Css.title}>Kontaktujte nás</div>
                <div className={Css.subTitle}>Udělejte první krok k bezstresovému účetnictví s Booke</div>
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </MapStatic>
    </div>
  );
};

export default FormBlockCzechRepublic;
