import Css from "./style.module.scss";

import React from "react";

import InViewport from "lib/common/InViewport";

const InfographicsCzechRepublic = () => {
  return (
    <div className={Css.infographics}>
      <div className="container">
        <InViewport>
          {({ shownOnce, inViewport }) => (
            <div className={`row-m${(shownOnce || inViewport) ? " fadeInFromBottom" : ""}`}>
              <div className={Css.left}>
                <img src="/images/fig-0-cz.svg" alt="" />
              </div>
              <div className={Css.right}>
                <h1 className={Css.textMain}>Automatizujte více a šetřete!</h1>
                <div className={Css.textSecondary}>
                  Ušetřete alespoň 1 hodinu za klienta měsíčně!
                </div>
                <a href="/signup" className={`button ${Css.button}`}>Vyzkoušejte Booke</a>
              </div>
            </div>
          )}
        </InViewport>
      </div>
    </div>
  );
};

export default InfographicsCzechRepublic;
