import Css from "./style.module.scss";

import React from "react";

import { CALENDLY_WIDGET_URL } from "const";
import classNames from "classnames";

const Main = ({ className }) => {
  return (
    <div className={classNames(Css.wrapper, className)}>
      <div className={Css.main}>
        <div className={`container ${Css.container}`}>
          <div className="row">
            <div className={Css.left}>
              <div className={Css.textLarge}>
                <div>
                  <span>Automate Tedious Bookkeeping</span>
                  <br />
                  in QuickBooks Online and Xero <img src="/images/lightning.png" />
                </div>
              </div>
              <div className={Css.textSmall}>
              Booke automates reconciliation and categorization with AI-driven RPA and Generative AI, saving you hours of manual work.
              </div>
              <div className={Css.buttons}>
                <a href="/signup" className={`button ${Css.button}`}>
                  <span>Start now</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none">
                    <path stroke="#fff" strokeWidth="1.5" d="m5.5 15 10-10M7.375 5H15.5v8.125" />
                  </svg>
                </a>
                <a href={CALENDLY_WIDGET_URL} className={`button stroke ${Css.button}`}>Request a Demo</a>
              </div>
            </div>
            <div className={Css.right}>
              <img src="/images/main-block-illustration.png" alt="Booke AI" />
            </div>
          </div>
        </div>
      </div>
      <div className={Css.backedBy}>
        <div className="container">
          <div className={Css.items}>
            <div className={Css.item}>
              <span>Backed by</span>
              <div>
                <img width="158" src="/images/alchemist-accelerator.png" />
              </div>
            </div>
            <div className={Css.item}>
              <span>Member of</span>
              <div>
                <img width="196" src="/images/microsoft-for-startups.png" />
              </div>
              <div>
                <img width="196" src="/images/google-for-startups.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
